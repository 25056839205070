import React from 'react'

class BlogSidebar extends React.Component {
    render() {
        return (
            <div className="blog-sidebar">
            </div>
        )
    }
}

export default BlogSidebar;