import React from 'react'
import './style.css'


const Welcome = () =>{
    return(
        <div className="welcome-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome-content">
                            <h2>Welcome to our big day</h2>
                            <p>We have been looking forward to this day for a long time. We are blessed to be able to share our special day with our closest friends and family. We hope everyone has the opportunity to witness our marriage and celebrate our new journey in life.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome;